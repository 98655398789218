/* dashboard.css */

.admin-app {
  font-family: Arial, sans-serif;
  background-color: #f1f1f1;
  margin: 0;
  padding: 0;
}

.admin-app-body {
  display: flex;
}

.admin-app-body-main-content {
  flex: 2;
  padding: 10px;
}

.admin-service-section {
  margin-top: 5px;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 8px;
}

.admin-service-section h2 {
  color: #0e0f0f;
  font-size: 24px;
  margin-bottom: 5px;
}

.admin-cards {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-card {
  flex: 1;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.admin-card h3 {
  font-size: 18px;
  color: #333;
}

.admin-card p {
  font-size: 24px;
  color: #4c9aff;
  font-weight: bold;
  margin-top: 10px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

table th,
table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

table th {
  background-color: #f2f2f2;
  font-weight: bold;
  color: #333;
}

table tr:nth-child(even) {
  background-color: #f9f9f9;
}

table td {
  color: #333;
}

table td:nth-child(2) {
  font-weight: bold;
  color: #4c9aff;
}

/* Styling for the buttons */
button {
  background-color: #4c9aff;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
}

button a {
  color: #fff;
  text-decoration: none;
}

/* Media query for mobile responsiveness */
@media screen and (max-width: 768px) {
  .admin-cards {
    flex-direction: column;
    align-items: center;
  }

  .admin-card {
    margin-top: 10px;
  }
}
