/* Shah
Krutik Yogeshkumar  
1002133150 */
.can-headline {
	column-span: all;
	background: #3490f9;
	color: white;
	font-size: 3vw;
	margin: 0;
	padding-left: 1rem;
}
.can-headline-list {
    text-align: center;
	column-span: all;
	background: #3490f9;
	color: white;
	font-size: 2vw;
	margin: 0;
	padding-left: 1rem;
}
.can-article-meta {
	column-span: all;
	padding-right: 1rem;
	padding-bottom: 1rem;
}
.can-article-meta * {
	display: inline-block;
	vertical-align: middle;
}
.can-article-meta svg {
	float: right;
	margin-top: -3rem;
	filter: saturate(0) contrast(5);
	border-radius: 50%;
	border: 0.5rem solid #3490f9;
	box-shadow: inset 0 0 0 2px #3490f9;
	box-sizing: border-box;
	transition: 0.75s;
}
.can-article-meta svg:hover {
	filter: saturate(1) contrast(1);
}
.can-article-meta p {
	margin: 0.25rem 0;
}
.can-article-meta span {
	text-decoration: underline;
	cursor: pointer;
}

.can-job-detail-heading {    
	background: #3490f9;
	color: white;
	font-size: 1rem;
	column-span: all;
	padding: 0.5rem;
	width: fit-content;
	max-width: 75%;
}

.can-job-detail-paragraph {
	line-height: 1.5rem;
	padding: 0 1.5rem;
	font-family: "Noticia Text", serif;

}

.can-job-detail-sub-heading {
    padding: 0 1.5rem;
    font-family: "Noticia Text", serif;
}

.can-begining_date {
	line-height: 1.5rem;
	padding: 0 1.5rem;
	font-family: "Noticia Text", serif;
}

ol { padding-left: 50px; }
.can-job-detail-list { 
    font-family: "Noticia Text", serif;
    color: #4F4F4F; 
    padding-left: 16px;
    margin-top: 24px;
    position: relative;
    font-size: 16px;
    line-height: 20px;
  
  &:before {
    font-family: "Noticia Text", serif;
    display: block;
 
    border-radius: 50%;
    border: 2px solid #3490f9;
    position: absolute; 

  }
}

strong { color: #292929; }

ol.alternating-colors { 
  li:nth-child(odd):before { border-color: #0BAD02; }
  li:nth-child(even):before { border-color: #2378D5; }
}