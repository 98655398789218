/* about-us.css */

/* Styling for navigation bar */
.navBar {
    background-color: #007BFF;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .navList {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  .navList li {
    margin-right: 20px;
  }
  
  .navList li a {
    text-decoration: none;
    color: #fff;
  }
  
  .loginButton a {
    text-decoration: none;
    color: #fff;
    background-color: #007BFF;
    padding: 8px 16px;
    border-radius: 5px;
  }
  
  /* Styling for the main section */
  .aboutSection {
    text-align: center;
    padding: 40px;
  }
  
  .aboutSection h1 {
    font-size: 36px;
  }
  
  .aboutSection p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .row {
    display: flex;
    justify-content: center;
  }
  
  .column {
    flex: 1;
    padding: 10px;
  }
  
  .card {
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .container {
    text-align: center;
  }
  
  .container h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .container .title {
    font-size: 18px;
    color: #007BFF;
    margin-bottom: 10px;
  }
  
  .container p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .button {
    background-color: #007BFF;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
  }
  
  /* Styling for the footer */
