/* Shah
Krutik Yogeshkumar  
1002133150 */
.can-ag-format-container {
    width: 1142px;
    margin: 0 auto;
  }

  body {
    background-color: #ffffff;
  }
  .can-ag-courses_box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  
    padding: 20px 0;
  }
  .can-ag-courses_item {
    -ms-flex-preferred-size: calc(33.33333% - 30px);
    flex-basis: calc(33.33333% - 30px);
  
    margin: 10px 15px 10px;
  
    overflow: hidden;
  
    border-radius: 14px;
  }
  .can-ag-courses-item_link {
    display: block;
    padding: 20px 20px;
    background-color: #ffffff;
  
    overflow: hidden;
  
    position: relative;
  }
  .can-ag-courses-item_link:hover,
  .can-ag-courses-item_link:hover .can-ag-courses-item_date {
    text-decoration: none;
    color: #FFF;
  }
  .can-ag-courses-item_link:hover .can-ag-courses-item_location {
    text-decoration: none;
    color: #FFF;
  }
  .can-ag-courses-item_link:hover .can-ag-courses-item_bg {
    -webkit-transform: scale(10);
    -ms-transform: scale(10);
    transform: scale(10);
  }
  .can-ag-courses-item_title {
    min-height: 87px;
    margin: 0 0 25px;
    overflow: hidden;
    font-weight: bold;
    font-size: 24px;
    color: black;
    z-index: 2;
    position: relative;
  }
  .can-ag-courses-item_date-box {
    font-size: 18px;
    color: black;
    z-index: 2;
    position: relative;
  }
  .can-ag-courses-item_date {
    font-weight: bold;
    color: black;
  
    -webkit-transition: color .5s ease;
    -o-transition: color .5s ease;
    transition: color .5s ease
  }
  .can-ag-courses-item_bg {
    height: 128px;
    width: 128px;
    background-color: #3490f9;
    z-index: 1;
    position: absolute;
    top: -75px;
    right: -75px;
    border-radius: 50%;
    -webkit-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
  }
  
  @media only screen and (max-width: 979px) {
    .can-ag-courses_item {
      -ms-flex-preferred-size: calc(50% - 30px);
      flex-basis: calc(50% - 30px);
    }
    .can-ag-courses-item_title {
      font-size: 24px;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .can-ag-format-container {
      width: 96%;
    }
  
  }
  @media only screen and (max-width: 639px) {
    .can-ag-courses_item {
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
    }
    .can-ag-courses-item_title {
      min-height: 72px;
      line-height: 1;
  
      font-size: 24px;
    }
    .can-ag-courses-item_link {
      padding: 22px 40px;
    }
    .can-ag-courses-item_date-box {
      font-size: 16px;
    }
  }