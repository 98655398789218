deiprofile.css
/* Reddy
   Sureddy Shivani
   1002072975  */
   body {
    font-family: Arial, sans-serif;
    background-color: #f1f1f1;
    margin: 0;
    padding: 0;
  }
  
  
  .dei-app-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .dei-app-header .dei-app-header-logo {
    display: flex;
    align-items: center;
  }
  
  .dei-app-header .dei-logo-icon img {
    width: 40px;
    height: 40px;
  }
  
  .dei-app-header .dei-logo-title span {
    font-size: 20px;
    font-weight: bold;
    margin-left: 10px;
  }
  
  .dei-app-header .dei-app-header-navigation .dei-tabs a {
    color: #555;
    text-decoration: none;
    padding: 20px 20px;
    margin-right: 50px;
    border-radius: 4px;
  }
  
  .dei-app-header .dei-app-header-navigation .dei-tabs a.active {
    background-color: #4c9aff;
    color: #fff;
  }
  
  .dei-app-header .dei-app-header-actions .dei-user-profile {
    background-color: transparent;
    border: none;
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .dei-app-header .dei-app-header-actions .dei-user-profile span:first-child {
    margin-right: 10px;
  }
  
  .dei-app-header .dei-app-header-actions .dei-user-profile img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  
  .dei-app-header .dei-app-header-actions .dei-app-header-actions-buttons button {
    background-color: transparent;
    border: none;
    padding: 5px;
    cursor: pointer;
    margin-left: 10px;
  }
  
  
  .dei-app-body {
    display: flex;
  }
  
  .dei-app-body-main-content {
    flex: 2;
    padding: 20px;
  }
  
  .dei-app-body-main-content .dei-service-section {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .dei-app-body-main-content .dei-service-section h2 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 20px;
  }
  
  .dei-app-body-main-content .dei-service-section table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .dei-app-body-main-content .dei-service-section th,
  .dei-app-body-main-content .dei-service-section td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ccc;
  }
  
  .dei-app-body-main-content .dei-service-section td button {
    background-color: #4c9aff;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
  }
  
  
  
  .dei-logo-title span {
    color: #4c9aff;
  }
  
  .dei-tabs a {
    background-color: #f1f1f1;
    color: #555;
    transition: background-color 0.3s;
  }
  
  .dei-tabs a:hover {
    background-color: #4c9aff;
    color: #fff;
  }
  
  .dei-user-profile span:first-child {
    color: #4c9aff;
    font-weight: bold;
  }
  
  .dei-icon-button.large i {
    font-size: 18px;
  }
  
  .dei-card.green {
    background-color: #a0d468;
    color: #fff;
  }
  
  .dei-card.olive {
    background-color: #c3c94c;
    color: #fff;
  }
  
  .dei-card.gray {
    background-color: #ccc;
    color: #333;
  }
  
  .dei-save-button, a{
    background-color: #4c9aff;
    color: #fff;
    text-decoration: none;
  }
  
  .dei-settings-button span {
    color: #4c9aff;
  }
  .dei-service-section {
    margin-top: 20px;
    background-color: #f1f1f1;
    padding: 20px;
    border-radius: 8px;
  }
  
  .dei-service-section h2 {
    color: #0e0f0f;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .dei-cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .dei-card {
    flex: 1;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .dei-card h3 {
    font-size: 18px;
    color: #333;
  }
  
  .dei-card p {
    font-size: 24px;
    color: #4c9aff;
    font-weight: bold;
    margin-top: 10px;
  }
  
  table {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
  }
  
  table th,
  table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  
  table th {
    background-color: #f2f2f2;
    font-weight: bold;
    color: #333;
  }
  
  table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  table td {
    color: #333;
  }
  
  table td:nth-child(2) {
    font-weight: bold;
    color: #4c9aff;
  }
  
  table + table {
    margin-top: 20px;
  }
  img {
    width: 200px; 
    height: auto; 
    margin: 10px; 
  }
  
  input[type=password]{
    background: white;
    height: 40px;
    border-radius: 4px;
    width: 100%;
    padding: 9px;
    border: 1px solid #4c9aff;
    margin-bottom: 15px;
    font-size: 16px;
    font: 700 16px 'Montserrat', sans-serif;
    box-shadow: 6px 6px 6px #cbced1, -6px -6px 6px white;
    transition: 0.5s;
  }
  
  input[type=text]{
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
  }
  
  input[type=date]{
    width: 20%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
  }
  
  
  input[type=submit]{
    background-color: #04AA6D;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  input[type=submit]:hover {
    background-color: #45a049;
  }
  
  .dei-container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
  }

  /* ... (existing styles remain unchanged) */

/* New styles for the updated form */
.dei-profile-section {
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: #fff;
  padding: 20px;
}

.dei-profile-section h3 {
  color: #0e0f0f;
  font-size: 24px;
  margin-bottom: 10px;
}

.dei-form-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.dei-form-field {
  flex: 1;
  margin-right: 20px;
}

.dei-form-field:last-child {
  margin-right: 0;
}

.dei-form-field label {
  display: block;
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
}

.dei-form-field input[type="text"],
.dei-form-field input[type="password"] {
  background: white;
  height: 40px;
  border-radius: 4px;
  width: 100%;
  padding: 9px;
  border: 1px solid #4c9aff;
  margin-bottom: 15px;
  font-size: 16px;
  font: 700 16px 'Montserrat', sans-serif;
  box-shadow: 6px 6px 6px #cbced1, -6px -6px 6px white;
  transition: 0.5s;
}

.dei-form-field input[type="submit"] {
  background-color: #04AA6D;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.dei-form-field input[type="submit"]:hover {
  background-color: #45a049;
}

/* ... (existing styles remain unchanged) */

  
  
  @media screen and (max-width: 768px) {
    .dei-cards {
      flex-direction: column;
      align-items: center;
    }
    
    .dei-card {
      margin-top: 10px;
    }
  }