/*  SHANIGARAM
    MANIDEEP
    1002024224
 */

body{
    background-color: LightGray;
}
.Nav_aca ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #FCFDFD;
  }

.Nav_aca li a {
    float: left;
    display: block;
    color: black;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }
.Nav_aca li a:hover {
    background-color: grey;
  }

  /* css for registration box */

  .aRegForm_aca {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }
  
  .academia_registration {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  }
  
  .academia_registration label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .academia_registration input[type="text"] {
    width: 100%;
    padding: 12px;
    margin-bottom: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .academia_registration button[type="submit"] {
    background-color: #3B5D76;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    margin: 0 auto;
  }
  
  
  /* .academia_registration button[type="submit"]:hover {
    background-color: white;
  } */
  
  .file-upload-btn {
    display: inline-block;
    padding: 12px 20px;
    background-color: #89A1AE;
    color: black;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .file-upload-btn input[type="file"] {
    display: none;
  }
  

  /* dashboard css */

.two-columns-grid {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal-width columns */
    gap: 20px; /* Gap between grid items */
    margin: 0 20px; /* Margin on left and right sides */
    margin-top: 10vh;
  }

  .dashboard-heading {
    display: inline-block;
    padding: 12px 20px;
    background-color: #3B5D76;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 15px;
    width: 85%;
  }

  .secondgrid {
    background-color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 15px;
    width: 85%;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .secondgrid {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* .grid-button{
    display: inline-block;
    padding: 12px 20px;
    background-color: #3B5D76;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    width: 75%;
}
.grid-button {
    margin-left: 10px; /* Adjust the margin as needed 
}


.button-container {
    display: flex;
    flex-direction: column; /* Set the flex direction to column 
}

.grid-button {
    margin: 8px 3px; /* Adjust the margin as needed 
} */

.button-container {
  display: flex;
  flex-direction: column; /* Set the flex direction to column */
}

.grid-button {
  display: inline-block;
  padding: 8px 20px;
  background-color: #3b5d76;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  text-align: center; /* Center the text inside the button */
  margin: 4px 3px; /* Adjust the margin as needed */
}



/* Add CSS styles to format the job details and preferences */

.job-details {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 20px;
}

.job-title h2 {
  margin: 0;
}

.job-description h4 {
  margin: 0;
  font-style: italic;
}

.job-preferences {
  display: flex;
}

.location-preferences, .candidate-preferences {
  flex: 1;
  border: 1px solid #ccc;
  padding: 10px;
  margin-right: 10px;
}

.location-preferences h3, .candidate-preferences h3 {
  margin-top: 0;
}

.location-preferences p, .candidate-preferences p {
  margin: 0;
}


.info-section {
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.candidate-details {
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

/* If you want to change the button color for the single button */
.single-button {
  background-color: #3b5d76;
}

