body {
    font-family: Arial, sans-serif;
    background-color: #f1f1f1;
    margin: 0;
    padding: 0;
  }
  a{
    text-decoration: none;
  }
  
  
  /* Header Styles */
  .can-app-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .can-app-header .can-app-header-logo {
    display: flex;
    align-items: center;
  }
  
  .can-app-header .can-logo-icon img {
    width: 40px;
    height: 40px;
  }
  
  .can-app-header .can-logo-title span {
    font-size: 20px;
    font-weight: bold;
    margin-left: 10px;
  }
  
  .can-app-header .can-app-header-navigation .can-tabs a {
    color: #555;
    text-decoration: none;
    padding: 10px 15px;
    margin-right: 10px;
    border-radius: 4px;
  }
  
  .can-app-header .can-app-header-navigation .can-tabs a.active {
    background-color: #4c9aff;
    color: #fff;
  }
  
  .can-app-header .can-app-header-actions .can-user-profile {
    background-color: transparent;
    border: none;
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .can-app-header .can-app-header-actions .can-user-profile span:first-child {
    margin-right: 10px;
  }
  
  .can-app-header .can-app-header-actions .can-user-profile img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  
  .can-app-header .can-app-header-actions .can-app-header-actions-buttons button {
    background-color: transparent;
    border: none;
    padding: 5px;
    cursor: pointer;
    margin-left: 10px;
  }
  
  /* Main Content Styles */
  .can-app-body {
    display: flex;
  }
  
  .can-app-body-main-content {
    flex: 2;
    padding: 20px;
  }
  
  .can-app-body-main-content .can-service-section {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .can-app-body-main-content .can-service-section h2 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 20px;
  }
  
  .can-app-body-main-content .can-service-section table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .can-app-body-main-content .can-service-section th,
  .can-app-body-main-content .can-service-section td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ccc;
  }
  
  .can-app-body-main-content .can-service-section td button {
    background-color: #4c9aff;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
  }
  
  /* Sidebar Styles */
  .can-app-body-sidebar {
    flex: 1;
    background-color: #f5f5f5;
    padding: 20px;
  }
  
  .can-app-body-sidebar {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .can-app-body-sidebar {
    font-size: 24px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 20px;
  }
  
  .can-app-body-sidebar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .can-app-body-sidebar{
    margin: 0;
  }
  
  .can-app-body-sidebar{
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .can-app-body-sidebar{
    width: 50px;
    height: 50px;
    fill: #ccc;
  }
  
  .can-app-body-sidebar  {
    fill: #4c9aff;
  }
  
  .can-app-body-sidebar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .can-app-body-sidebar  {
    display: flex;
    align-items: center;
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 4px;
  }
  
  .can-app-body-sidebar {
    margin-right: 10px;
  }
  
  .can-app-body-sidebar  div {
    display: flex;
    align-items: center;
  }
  
  .can-app-body-sidebar  span {
    margin-right: 10px;
  }
  
  .can-app-body-sidebar  .can-icon-button {
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
  }
  
  .can-app-body-sidebar {
    margin-bottom: 20px;
  }
  
  .can-app-body-sidebar  {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .can-app-body-sidebar {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .can-app-body-sidebar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .can-app-body-sidebar {
    background-color: #4c9aff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .can-app-body-sidebar  {
    background-color: transparent;
    border: none;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .can-app-body-sidebar  .can-settings-button i {
    margin-right: 5px;
  }
  
  /* Colors */
  .can-logo-title span {
    color: #4c9aff;
  }
  
  .can-tabs a {
    background-color: #f1f1f1;
    color: #555;
    transition: background-color 0.3s;
  }
  
  .can-tabs a:hover {
    background-color: #4c9aff;
    color: #fff;
  }
  
  .can-user-profile span:first-child {
    color: #4c9aff;
    font-weight: bold;
  }
  
  .can-icon-button.large i {
    font-size: 18px;
  }
  
  .can-card.green {
    background-color: #a0d468;
    color: #fff;
  }
  
  .can-card.olive {
    background-color: #c3c94c;
    color: #fff;
  }
  
  .can-card.gray {
    background-color: #ccc;
    color: #333;
  }
  
  .can-save-button {
    background-color: #4c9aff;
    color: #fff;
  }
  
  .can-settings-button span {
    color: #4c9aff;
  }
  
  .can-dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .can-dropdown-content a {
    float: none;
    color: black;
    padding: 10px 12px;
    text-decoration: none;
    display: block;
    text-align: center;
  }
  
  .can-dropdown-content a:hover {
    background-color: #c1ceeb;
  }
  
  .can-dropdown:hover .can-dropdown-content {
    display: block;
  }
  .can-dropdown {
    float: left;
    overflow: hidden;
  }

  .can-service-section {
    margin-top: 20px;
    background-color: #f1f1f1;
    padding: 20px;
    border-radius: 8px;
  }
  
  .can-service-section h2 {
    color: #0e0f0f;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
/* Shah
Krutik Yogeshkumar  
1002133150 */
.can-cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .can-card {
    flex: 1;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .can-card h3 {
    font-size: 18px;
    color: #4c9aff;
  }
  
  .can-card p {
    font-size: 24px;
    color: #050505;
    font-weight: bold;
    margin-top: 10px;
  }
  
  /* Reset default table styles */
  table {
    border-collapse: collapse;
    width: 100%;
  }
  
  table th,
  table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  /* Style table header */
  table th {
    background-color: #f2f2f2;
    font-weight: bold;
    color: #333;
  }
  
  /* Style table rows */
  table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  /* Style table cells */
  table td {
    color: #333;
  }
  
  /* Style specific table cells */
  table td:nth-child(2) {
    /* Status column */
    font-weight: bold;
    color: #4c9aff;
  }
  
  /* Add some spacing between tables */
  table + table {
    margin-top: 20px;
  }
  img {
    width: 200px; 
    height: auto; 
    margin: 10px; 
  }
  /* Responsive Styling */
  
  @media screen and (max-width: 768px) {
    .can-cards {
      flex-direction: column;
      align-items: center;
    }
    
    .can-card {
      margin-top: 10px;
    }
  }
  
  