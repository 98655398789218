/* Reddy
   Sureddy Shivani
   1002072975  */
body {
    font-family: Arial, sans-serif;
    background-color: #f1f1f1;
    margin: 0;
    padding: 0;
  }
  
  
  .dei-app-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .dei-app-header .dei-app-header-navigation .dei-tabs a {
    float: left;
    color: #555;
    text-decoration: none;
    padding: 10px 15px;
    margin-right: 80px;
    border-radius: 2px;
  }

  .dei-app-header .dei-app-header-navigation .dei-tabs a.active {
    background-color: #4c9aff;
    color: #fff;
  }
  
  .dei-app-header .dei-app-header-actions .dei-user-profile {
    background-color: transparent;
    border: none;
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .dei-app-header .dei-app-header-actions .dei-user-profile span:first-child {
    margin-right: 10px;
  }
  
  .dei-app-header .dei-app-header-actions .dei-user-profile img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  
  .dei-app-header .dei-app-header-actions .dei-app-header-actions-buttons button {
    background-color: transparent;
    border: none;
    padding: 5px;
    cursor: pointer;
    margin-left: 10px;
  }