/* 1. Darshil Shah - 1002084331
2. Krutik Yogeshkumar Shah - 1002133150
3. Maaz Ahmed Sheriff - 1002032363
4. Manideep Shanigaram - 1002024224
5. Sureddy Shivani Reddy - 1002072975 */
body {font-family: Arial, Helvetica, sans-serif;}
* {box-sizing: border-box;}

input[type=text]{
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}
input[type=date]{
  width: 20%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}


input[type=submit]{
  background-color: #04AA6D;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type=submit]:hover {
  background-color: #45a049;
}

.admin-container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}
