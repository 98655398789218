.app {
  width: 100%;
  background-color: #f5f5f5;
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: white;
  padding: 1rem;
}

.app-header-navigation {
  flex: 1;
}

.tabs {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.tabs a {
  color: white;
  text-decoration: none;
  margin-right: 1rem;
  padding: 0.5rem;
  border-radius: 3px;
  transition: background-color 0.3s ease;
}

.tabs a:hover {
  background-color: #555;
}

.app-header-actions {
  display: flex;
  align-items: center;
}

.user-profile {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  margin-right: 1rem;
}

.user-profile img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 0.5rem;
}

.icon-button {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1.5rem;
  margin-right: 0.5rem;
  padding: 0;
}

.large {
  font-size: 2rem;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #333;
  min-width: 120px;
  right: 0;
  border-radius: 3px;
  padding: 0.5rem;
}

.dropdown-content button {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0;
  margin: 0;
  display: block;
  width: 100%;
  text-align: left;
  font-size: 1rem;
}

.dropdown-content button a {
  color: white;
  text-decoration: none;
  padding: 0.25rem;
  display: block;
}

.dropdown-content button:hover {
  background-color: #555;
}

.dropdown:hover .dropdown-content {
  display: block;
}
