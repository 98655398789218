/* nav.css */

/* Main styles for the app header */
.can-app-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .can-app-header-logo {
    display: flex;
    align-items: center;
  }
  
  .can-logo-title {
    font-size: 24px;
    font-weight: bold;
    margin-left: 10px;
    color: #4c9aff;
  }
  
  .can-app-header-navigation {
    margin-left: 20px;
  }
  
  .can-tabs a {
    color: #555;
    text-decoration: none;
    padding: 8px 15px;
    margin-right: 10px;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .can-tabs a:hover {
    background-color: #4c9aff;
    color: #fff;
  }
  
  /* Styling for the user profile dropdown */
  .can-app-header-actions {
    display: flex;
    align-items: center;
  }
  
  .can-user-profile {
    background-color: transparent;
    border: none;
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .can-user-profile span:first-child {
    margin-right: 10px;
    color: #4c9aff;
    font-weight: bold;
  }
  
  .can-user-profile img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  
  /* Styling for the icons in the actions section */
  .can-app-header-actions-buttons {
    display: flex;
    align-items: center;
  }
  
  .can-icon-button {
    background-color: transparent;
    border: none;
    padding: 5px;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .can-icon-button.large {
    font-size: 18px;
  }
  
  /* Styling for the dropdown content */
  .can-dropdown-content1 {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  
  .can-dropdown-content1 a {
    float: none;
    color: black;
    padding: 6px 8px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  
  .can-dropdown-content1 a:hover {
    background-color: #c1ceeb;
  }
  
  /* Show the dropdown content on hover */
  .can-dropdown:hover .dropdown-content {
    display: block;
  }
  
  /* Media query for mobile responsiveness */
  @media screen and (max-width: 768px) {
    .can-app-header {
      flex-direction: column;
    }
    .can-app-header-navigation {
      margin: 10px 0;
    }
    .can-tabs a {
      margin-right: 0;
      margin-bottom: 10px;
      display: block;
    }
  }
  