/* Reddy
   Sureddy Shivani
   1002072975  */
body {
  font-family: Arial, sans-serif;
  background-color: #f1f1f1;
  margin: 0;
  padding: 0;
}


.dei-app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dei-app-header .dei-app-header-logo {
  display: flex;
  align-items: center;
}

.dei-app-header .dei-logo-icon img {
  width: 40px;
  height: 40px;
}

.dei-app-header .dei-logo-title span {
  font-size: 10px;
  font-weight: bold;
  margin-left: 5px;
}

.dei-app-header .dei-app-header-navigation .dei-tabs a {
  color: #555;
  text-decoration: none;
  padding: 5px 5px;
  margin-right: 5px;
  border-radius: 4px;
}

.dei-app-header .dei-app-header-navigation .dei-tabs a.active {
  background-color: #4c9aff;
  color: #fff;
}

.dei-app-header .dei-app-header-actions .dei-user-profile {
  background-color: transparent;
  border: none;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dei-app-header .dei-app-header-actions .dei-user-profile span:first-child {
  margin-right: 5px;
}

.dei-app-header .dei-app-header-actions .dei-user-profile img {
  width: 50px;
  height: 30px;
  border-radius: 50%;
}

.dei-app-header .dei-app-header-actions .dei-app-header-actions-buttons button {
  background-color: transparent;
  border: none;
  padding: 5px;
  cursor: pointer;
  margin-left: 10px;
}


.dei-app-body {
  display: flex;
}

.dei-app-body-main-content {
  flex: 3;
  padding: 20px;
}

.dei-app-body-main-content .dei-service-section {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
}

.dei-app-body-main-content .dei-service-section h2 {
  font-size: 24px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 20px;
}

.dei-app-body-main-content .dei-service-section table {
  width: 100%;
  border-collapse: collapse;
}

.dei-app-body-main-content .dei-service-section th,
.dei-app-body-main-content .dei-service-section td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

.dei-app-body-main-content .dei-service-section td button {
  background-color: #4c9aff;
  color: #fff;
  border: none;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}


.dei-app-body-sidebar {
  flex: 1;
  background-color: #f5f5f5;
  padding: 20px;
}

.dei-app-body-sidebar {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
}

.dei-app-body-sidebar {
  font-size: 24px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 20px;
}

.dei-app-body-sidebar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.dei-app-body-sidebar{
  margin: 0;
}

.dei-app-body-sidebar{
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-right: 10px;
}

.dei-app-body-sidebar{
  width: 50px;
  height: 50px;
  fill: #ccc;
}

.dei-app-body-sidebar  {
  fill: #4c9aff;
}

.dei-app-body-sidebar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.dei-app-body-sidebar  {
  display: flex;
  align-items: center;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 4px;
}

.dei-app-body-sidebar {
  margin-right: 10px;
}

.dei-app-body-sidebar  div {
  display: flex;
  align-items: center;
}

.dei-app-body-sidebar  span {
  margin-right: 10px;
}

.dei-app-body-sidebar  .dei-icon-button {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.dei-app-body-sidebar {
  margin-bottom: 20px;
}

.dei-app-body-sidebar  {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.dei-app-body-sidebar {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.dei-app-body-sidebar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dei-app-body-sidebar {
  background-color: #4c9aff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.dei-app-body-sidebar  {
  background-color: transparent;
  border: none;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dei-app-body-sidebar  .dei-settings-button i {
  margin-right: 5px;
}


.dei-logo-title span {
  color: #4c9aff;
}

.dei-tabs a {
  background-color: #f1f1f1;
  color: #555;
  transition: background-color 0.3s;
}

.dei-tabs a:hover {
  background-color: #4c9aff;
  color: #fff;
}

.dei-user-profile span:first-child {
  color: #4c9aff;
  font-weight: bold;
}

.dei-icon-button.large i {
  font-size: 18px;
}

.dei-card.green {
  background-color: #a0d468;
  color: #fff;
}

.dei-card.olive {
  background-color: #c3c94c;
  color: #fff;
}

.dei-card.gray {
  background-color: #ccc;
  color: #333;
}

.dei-save-button {
  background-color: #4c9aff;
  color: #fff;
}

.dei-settings-button span {
  color: #4c9aff;
}

.dei-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dei-dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dei-dropdown-content a:hover {
  background-color: #c1ceeb;
}

.dei-dropdown:hover .dei-dropdown-content {
  display: block;
}
.dei-dropdown {
  float: left;
  overflow: hidden;
}


.dei-card {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 4px;
  flex: 1;
  text-align: center;
} 
.dei-service-section {
  margin-top: 20px;
  background-color: #f1f1f1;
  padding: 20px;
  border-radius: 8px;
}

.dei-service-section h2 {
  color: #0e0f0f;
  font-size: 24px;
  margin-bottom: 10px;
}

.dei-cards {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dei-card {
  flex: 1;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.dei-card h3 {
  font-size: 18px;
  color: #333;
}

.dei-card p {
  font-size: 24px;
  color: #4c9aff;
  font-weight: bold;
  margin-top: 10px;
}

table {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
}

table th,
table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}


table th {
  background-color: #f2f2f2;
  font-weight: bold;
  color: #333;
}


table tr:nth-child(even) {
  background-color: #f9f9f9;
}

table td {
  color: #333;
}

table td:nth-child(2) {
  font-weight: bold;
  color: #4c9aff;
}


table + table {
  margin-top: 20px;
}
img {
  width: 200px; 
  height: auto; 
  margin: 10px; 
}

@media screen and (max-width: 768px) {
  .dei-cards {
    flex-direction: column;
    align-items: center;
  }
  
  .dei-card {
    margin-top: 10px;
  }
}
