/* 1. Darshil Shah - 1002084331
2. Krutik Yogeshkumar Shah - 1002133150
3. Maaz Ahmed Sheriff - 1002032363
4. Manideep Shanigaram - 1002024224
5. Sureddy Shivani Reddy - 1002072975 */
    body {
  font-family: Arial, sans-serif;
  background-color: #f1f1f1;
  margin: 0;
  padding: 0;
}


.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.app-header .app-header-logo {
  display: flex;
  align-items: center;
}

.app-header .logo-icon img {
  width: 40px;
  height: 40px;
}

.app-header .logo-title span {
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
}

.app-header .app-header-navigation .tabs a {
  color: #555;
  text-decoration: none;
  padding: 20px 35px;
  margin-right: 50px;
  border-radius: 4px;
}

.app-header .app-header-navigation .tabs a.active {
  background-color: #4c9aff;
  color: #fff;
}

.app-header .app-header-actions .user-profile {
  background-color: transparent;
  border: none;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.app-header .app-header-actions .user-profile span:first-child {
  margin-right: 10px;
}

.app-header .app-header-actions .user-profile img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.app-header .app-header-actions .app-header-actions-buttons button {
  background-color: transparent;
  border: none;
  padding: 5px;
  cursor: pointer;
  margin-left: 10px;
}


.app-body {
  display: flex;
}

.app-body-main-content {
  flex: 2;
  padding: 20px;
}

.app-body-main-content .service-section {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
}

.app-body-main-content .service-section h2 {
  font-size: 24px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 20px;
}

.app-body-main-content .service-section table {
  width: 100%;
  border-collapse: collapse;
}

.app-body-main-content .service-section th,
.app-body-main-content .service-section td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

.app-body-main-content .service-section td button {
  background-color: #4c9aff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}



.logo-title span {
  color: #4c9aff;
}

.tabs a {
  background-color: #f1f1f1;
  color: #555;
  transition: background-color 0.3s;
}

.tabs a:hover {
  background-color: #4c9aff;
  color: #fff;
}

.user-profile span:first-child {
  color: #4c9aff;
  font-weight: bold;
}

.icon-button.large i {
  font-size: 18px;
}

.card.green {
  background-color: #a0d468;
  color: #fff;
}

.card.olive {
  background-color: #c3c94c;
  color: #fff;
}

.card.gray {
  background-color: #ccc;
  color: #333;
}

.save-button {
  background-color: #4c9aff;
  color: #fff;
}

.settings-button span {
  color: #4c9aff;
}


.service-section {
  margin-top: 20px;
  background-color: #f1f1f1;
  padding: 20px;
  border-radius: 8px;
}

.service-section h2 {
  color: #0e0f0f;
  font-size: 24px;
  margin-bottom: 10px;
}

.cards {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card {
  flex: 1;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.card h3 {
  font-size: 18px;
  color: #333;
}

.card p {
  font-size: 24px;
  color: #4c9aff;
  font-weight: bold;
  margin-top: 10px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

table th,
table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}


table th {
  background-color: #f2f2f2;
  font-weight: bold;
  color: #333;
}

table tr:nth-child(even) {
  background-color: #f9f9f9;
}


table td {
  color: #333;
}

table td:nth-child(2) {

  font-weight: bold;
  color: #4c9aff;
}


table + table {
  margin-top: 20px;
}
body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    background-color: #f7f7f7;
}

.service-section {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.reports {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.report {
    flex-basis: 30%;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.report img {
    max-width: 100%;
    height: auto;
    margin-top: 10px;
}

h3 {
    color: #333;
    margin-bottom: 10px;
}
img {
  width: 500px; 
  height: 500px; 

}
