/* Shah
Krutik Yogeshkumar  
1002133150 */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.can-container {
  display: flex;
  height: 100vh;
  background-color: #f0f0f0;
}

.can-list {
  flex: 1;
  background-color: #f0f0f0;
  overflow-y: scroll; 
  height:flex;
}

.can-details {
  flex: 2;
  background-color: #fff;
  border-left: 1px solid #ccc;
  height:flex;
}

.can-item-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.can-item:last-child {
  border-bottom: none;
}

.can-detail-content {
  display: none;
}

.can-detail-content.active {
  display: block;
}





/* 
Login.php 
<?php

header("Access-Control-Allow-Origin: *");
header("Access-Control-Allow-Headers: access");
header("Access-Control-Allow-Methods: POST");
header("Content-Type: application/json; charset=UTF-8");
header("Access-Control-Allow-Headers: Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With");
header('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,OPTIONS');

// Allow from any origin
if (isset($_SERVER['HTTP_ORIGIN'])) {
    header("Access-Control-Allow-Origin: {$_SERVER['HTTP_ORIGIN']}");
    header('Access-Control-Allow-Credentials: true');
    header('Access-Control-Max-Age: 86400');    // cache for 1 day
}

// Access-Control headers are received during OPTIONS requests
if ($_SERVER['REQUEST_METHOD'] == 'OPTIONS') {
    if (isset($_SERVER['HTTP_ACCESS_CONTROL_REQUEST_METHOD']))
        header("Access-Control-Allow-Methods: GET, POST, OPTIONS");         

    if (isset($_SERVER['HTTP_ACCESS_CONTROL_REQUEST_HEADERS']))
        header("Access-Control-Allow-Headers:{$_SERVER['HTTP_ACCESS_CONTROL_REQUEST_HEADERS']}");

    exit(0);
}

function msg($success, $status, $message, $extra = []) {
    return array_merge([
        'success' => $success,
        'status' => $status,
        'message' => $message
    ], $extra);
}

require './classes/database.php';
$db_connection = new Database();
$conn = $db_connection->dbConnection();

// GET DATA FROM REQUEST
$data = json_decode(file_get_contents("php://input"));
$returnData = [];

// IF REQUEST METHOD IS NOT POST
if ($_SERVER["REQUEST_METHOD"] != "POST") {
    $returnData = msg(0, 404, 'Page Not Found!');
} else {
    // Check if the required parameters are provided
    if (!isset($data->tableName) || !isset($data->columns)) {
        $returnData = msg(0, 400, 'Invalid request. Missing table name or column data.');
    } else {
        $tableName = trim($data->tableName);
        $columns = $data->columns;

        // Create the INSERT query dynamically
        $insert_query = "INSERT INTO `$tableName` (";
        $placeholders = '';
        $columnNames = [];

        foreach ($columns as $column => $value) {
            $columnNames[] = $column;
            $placeholders .= ':' . $column . ',';
        }

        $insert_query .= implode(',', $columnNames) . ') VALUES (' . rtrim($placeholders, ',') . ')';
        $insert_stmt = $conn->prepare($insert_query);

        // DATA BINDING
        foreach ($columns as $column => $value) {
            $insert_stmt->bindValue(':' . $column, trim($value), PDO::PARAM_STR);
        }

        try {
            $insert_stmt->execute();
            $returnData = msg(1, 201, 'Record inserted successfully.');
        } catch (PDOException $e) {
            $returnData = msg(0, 500, 'Database error: ' . $e->getMessage());
        }
    }
}

echo json_encode($returnData);

?> */
