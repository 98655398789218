/* Shah
Krutik Yogeshkumar  
1002133150 */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 20px;
    background-color: #f2f2f2;
  }

  h1 {
    color: #333;
    text-align: center;
  }

  .can-candidate-from {
    max-width: 6000px;
    margin: 20px auto;
    background-color: #4c9aff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  label {
    display: block;
    text-align: justify;
    padding: 10px;
    font: 700 20px 'Montserrat', sans-serif;
    margin-bottom: 5px;
    font-weight: bold;
  }

  input[type="text1"],
  input[type="email"],
  input[type="password"],
  textarea {
    background: white ;
    height: 40px;
    border-radius: 15px;
    width: 80%;
    padding: 9px;
    border: 1px solid #4c9aff;
    margin-bottom: 15px;
    font-size: 16px;
    font: 700 16px 'Montserrat', sans-serif;
    box-shadow: 6px 6px 6px #cbced1, -6px -6px 6px white;
    transition: 0.5s;
  }

  textarea {
    resize: vertical;
  }

  input[type="file"] {
    display: none;
  }


  .can-file-upload-label {
    display: block;
    padding: 20px 10px;
    background-color: #4c9aff;
    color: white;
    border: none;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 20px;
  }

  .can-file-upload-label:hover {
    background-color: #4c9aff;
  }

  .can-upload-file-info {
    margin-bottom: 12px;
    width: 80%;
    padding: 9px;
    text-align: center;
    margin:auto;
  }

  button[type="submit"] {
    padding: 12px 12px;
    background-color: #4c9aff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s ease;
  }

  button[type="submit"]:hover {
    background-color: #4c9aff;
  }

  button[type="button1"] {
    padding: 12px 12px;
    background-color: #4c9aff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s ease;
  }

  button[type="button1"]:hover {
    background-color: #4c9aff;
  }

  .can-apply-button {
    /* Add a unique class to increase specificity */
    /* Example: */
    /* Apply the styles only to buttons with the "apply-button" class */
    /* You can also use an ID for even higher specificity */
    /* Example: #apply-button { ... } */
    background-color: #007bff;
    color: #fff;

  }