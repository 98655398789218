/* Shah
Krutik Yogeshkumar  
1002133150 */
body {
    font-family: Arial, sans-serif;
    background-color: #f1f1f1;
    margin: 0;
    padding: 0;
  }
  
  /* Header Styles */
  .can-app-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .can-app-header .can-app-header-logo {
    display: flex;
    align-items: center;
  }
  
  .can-app-header .can-logo-icon img {
    width: 40px;
    height: 40px;
  }
  
  .can-app-header .can-logo-title span {
    font-size: 20px;
    font-weight: bold;
    margin-left: 10px;
  }
  
  .can-app-header .can-app-header-navigation .can-tabs a {
    color: #555;
    text-decoration: none;
    padding: 10px 15px;
    margin-right: 10px;
    border-radius: 4px;
  }
  
  .can-app-header .can-app-header-navigation .can-tabs a.active {
    background-color: #4c9aff;
    color: #fff;
  }
  
  .can-app-header .can-app-header-actions .can-user-profile {
    background-color: transparent;
    border: none;
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .can-app-header .can-app-header-actions .can-user-profile span:first-child {
    margin-right: 10px;
  }
  
  .can-app-header .can-app-header-actions .can-user-profile img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  
  .can-app-header .can-app-header-actions .can-app-header-actions-buttons button {
    background-color: transparent;
    border: none;
    padding: 5px;
    cursor: pointer;
    margin-left: 10px;
  }
  
  /* Main Content Styles */
  .can-app-body {
    display: flex;
  }
  
  .can-app-body-main-content {
    flex: 2;
    padding-top: 0px;
    padding-right: 20px;
    padding-left: 20px;

        
  }
  
  .can-app-body-main-content .can-service-section {
    background-color: #fff;
    margin-bottom: 20px;
  }
  
  .can-app-body-main-content .can-service-section h2 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 20px;
  }
  