/* contact-us.css */

/* Styling for navigation bar */
.navBar {
    background-color: #007BFF;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .navList {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  .navList li {
    margin-right: 20px;
  }
  
  .navList li a {
    text-decoration: none;
    color: #fff;
  }
  
  .loginButton a {
    text-decoration: none;
    color: #fff;
    background-color: #007BFF;
    padding: 8px 16px;
    border-radius: 5px;
  }
  
  /* Styling for the main section */
  .contactUsMainSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
  }
  
  .textBox {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .text-heading {
    font-size: 24px;
  }
  
  .contactUsForm {
    max-width: 400px;
    width: 100%;
  }
  
  .contactUsForm label {
    font-weight: bold;
  }
  
  .contactUsForm input[type="text"],
  .contactUsForm textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .contactUsForm textarea {
    resize: vertical;
  }
  
  .contactUsForm input[type="submit"] {
    background-color: #007BFF;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  /* Styling for the footer */
  footer {
    background-color: #333;
    color: #fff;
    padding: 10px;
    text-align: center;
  }
  
  .social-media {
    display: flex;
    justify-content: center;
  }
  
  .social-media a {
    color: #fff;
    margin: 0 5px;
  }
  
  /* Font Awesome Icons */
  .fa {
    font-size: 24px;
  }
  