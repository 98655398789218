/* Home.css */

.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('https://img.freepik.com/premium-vector/job-career-with-big-word-style-team-people-office-around-with-business-icon_25147-252.jpg');
    background-size: cover;
    background-position: center;
  }
  
  nav {
    background-color: #007BFF;
    width: 100%;
  }
  
  nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
  }
  
  nav li {
    margin: 10px;
  }
  
  nav a {
    text-decoration: none;
    color: #fff;
    padding: 10px;
  }
  
  .content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    background-size: cover;
    background-position: center;
    min-height: 600px; /* Adjust this value to fit your content */
  }
  
  .image-container img {
    max-width: 400px;
    height: auto;
  }
  
  .details-container {
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(5px); /* Add a blur effect to the background */
    border-radius: 5px;
    animation: fadeInUp 1s both;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  h1 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  button {
    background-color: #007BFF;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
.fa{
  display: block;
  color: #000;
  margin: 5px;
  font-size: 15px;
  cursor: pointer;
}